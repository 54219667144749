<template>
  <div class="staplerkurse">
    <!-- Header img -->
    <b-container fluid>
      <b-row class="mb-4">
        <b-img
          fluid
          :src="
            node_env === 'production'
              ? kursseiten.motorsage_weiterfuhrungskurs_5_tage_header_bild.data
                  .attributes.url
              : api_url +
                kursseiten.motorsage_weiterfuhrungskurs_5_tage_header_bild.data
                  .attributes.url
          "
        ></b-img>
      </b-row>
    </b-container>

    <b-container>
      <!-- Datum wahlen -->
      <b-row class="mb-5">
        <b-col md="4">
          <b-form-datepicker
            :date-disabled-fn="dateDisabled"
            :label-no-date-selected="'KURSDATEN HIER KLICKEN'"
            v-model="selected_date"
            class="mb-2 mt-3 calendar-picker"
          ></b-form-datepicker>
          <b>KURSDATEN HIER KLICKEN</b>
        </b-col>
      </b-row>

      <!-- Content -->

      <b-row>
        <b-col md="8">
          <div
            v-html="kursseiten.motorsage_weiterfuhrungskurs_5_tage_text"
          ></div>
        </b-col>
      </b-row>

      <!-- Formular -->
      <div
        v-if="isCourseAvailable(this.selected_date, this.kurs)"
        class="row formular"
      >
        <div class="col-md-8">
          <form action="https://formspree.io/f/mqknlwpn" method="post">
            <!-- Select privat or firma -->
            <div class="form-group mb-4 w-50">
              <label for=""
                ><b
                  >Kurs-Anmeldeformular<span class="text-danger">*</span></b
                ></label
              >
              <small class="form-text text-muted">Privat oder Firma</small>
              <select
                v-model="anmeldeinformation"
                class="form-control"
                name="anmeldeinformationen"
              >
                <option value="firma">FIRMA</option>
                <option value="privatperson">PRIVATPERSON</option>
              </select>
            </div>
            <!-- Selected Date (hidden / it's only for email) -->
            <input
              v-model="selected_date"
              type="text"
              class="form-control w-50 d-none"
              name="datum"
            />
            <input
              v-model="kurs"
              type="text"
              class="form-control w-50 d-none"
              name="kurs"
            />
            <!-- Firma name -->
            <div
              v-if="anmeldeinformation == 'firma'"
              class="form-group mb-4 w-50"
            >
              <label for=""
                ><b>Firma <span class="text-danger">*</span></b></label
              >
              <input
                v-model="name"
                type="text"
                class="form-control"
                name="firma_name"
              />
            </div>
            <!-- Privat name -->
            <ul v-else class="list-inline mb-1">
              <li class="list-inline-item">
                <div class="form-group">
                  <label for=""
                    ><b>Name <span class="text-danger">*</span></b></label
                  >
                  <input
                    v-model="name"
                    type="text"
                    class="form-control"
                    name="vorname"
                  />
                  <small class="form-text text-muted">Vorname</small>
                </div>
              </li>
              <li class="list-inline-item">
                <div class="form-group">
                  <input type="text" class="form-control" name="nachname" />
                  <small class="form-text text-muted">Nachname</small>
                </div>
              </li>
            </ul>

            <!-- Mutual fields -->

            <!-- Adresse -->
            <div class="form-group mb-4">
              <label for=""
                ><b
                  ><span v-if="anmeldeinformation == 'privatperson'"
                    >Adresse</span
                  >
                  <span v-else>Rechnungsadresse Firma</span>
                  <span class="text-danger">*</span></b
                ></label
              >
              <input
                v-model="adresse"
                type="text"
                class="form-control"
                name="strasse"
              />
              <small class="form-text text-muted">Strasse</small>
            </div>

            <ul class="list-inline mb-1">
              <li class="list-inline-item">
                <!-- Ort -->
                <div class="form-group">
                  <input type="text" class="form-control" name="ort" />
                  <small class="form-text text-muted">Ort</small>
                </div>
              </li>

              <li class="list-inline-item">
                <!-- Plz -->
                <div class="form-group">
                  <input type="text" class="form-control" name="plz" />
                  <small class="form-text text-muted">PLZ</small>
                </div>
              </li>
            </ul>

            <!-- Land -->
            <div class="form-group mb-4 w-50">
              <select class="form-control" name="land">
                <option selected value="Switzerland">Switzerland</option>
                <option
                  v-for="country in countries"
                  :key="country.index"
                  :value="country.name.common"
                >
                  {{ country.name.common }}
                </option>
              </select>
              <small class="form-text text-muted">Land</small>
            </div>

            <!-- Telefon -->
            <div class="form-group mb-4">
              <label for=""
                ><b
                  >Telefon
                  <span v-if="anmeldeinformation == 'firma'">Firma</span>
                  <span v-else>Privat</span>
                  <span class="text-danger">*</span></b
                ></label
              >
              <input
                v-model="telefon"
                type="text"
                class="form-control w-50"
                name="telefon"
              />
            </div>

            <!-- Mobile -->
            <div
              v-if="anmeldeinformation == 'privatperson'"
              class="form-group mb-4"
            >
              <label for=""><b>Mobile </b></label>
              <input type="text" class="form-control w-50" name="mobile" />
            </div>

            <!-- E-mail -->
            <div class="form-group mb-4">
              <label for=""
                ><b
                  >E-mail
                  <span v-if="anmeldeinformation == 'firma'">Firma</span></b
                ><span class="text-danger">*</span></label
              >
              <input
                v-model="email"
                type="email"
                class="form-control w-50"
                name="email"
              />
            </div>

            <!-- Private selected -->
            <div v-if="anmeldeinformation == 'privatperson'">
              <!-- Gerbutrsdatum Privat-->
              <div class="form-group mb-4">
                <label for=""
                  ><b>Gerbutrsdatum </b
                  ><span class="text-danger">*</span></label
                >
                <input
                  v-model="geburtsdatum"
                  placeholder="tt.mm.jjjj"
                  type="text"
                  class="form-control w-50"
                  name="geburtsdatum"
                />
              </div>

              <!-- E-learning email -->
              <div class="form-group mb-4">
                <label for=""><b>E-Mail für E-Learning</b></label>
                <input
                  type="email"
                  class="form-control w-50"
                  name="e-learning-email"
                />
              </div>
            </div>

            <!-- if Firma -->

            <!-- Anzahl Personen -->
            <div class="form-group mb-4">
              <label for=""
                ><b>Anzahl Personen </b
                ><span class="text-danger">*</span></label
              >
              <small class="form-text text-muted"
                >Wieivele Personen möchten Sie für den Kurs anmelden?</small
              >
              <select
                v-model="anzahl_personen"
                class="form-control w-50"
                name="anzahl_personen"
              >
                <option
                  :disabled="
                    this.registeredPersons(this.kurs) + 1 >
                    this.filteredKurses.max_personen
                  "
                  selected
                  value="1"
                >
                  1 PERSON
                </option>
                <option
                  :disabled="
                    this.registeredPersons(this.kurs) + 2 >
                    this.filteredKurses.max_personen
                  "
                  value="2"
                >
                  2 PERSONEN
                </option>
                <option
                  :disabled="
                    this.registeredPersons(this.kurs) + 3 >
                    this.filteredKurses.max_personen
                  "
                  value="3"
                >
                  3 PERSONEN
                </option>
                <option
                  :disabled="
                    this.registeredPersons(this.kurs) + 4 >
                    this.filteredKurses.max_personen
                  "
                  value="4"
                >
                  4 PERSONEN
                </option>
                <option
                  :disabled="
                    this.registeredPersons(this.kurs) + 5 >
                    this.filteredKurses.max_personen
                  "
                  value="5"
                >
                  5 PERSONEN
                </option>
                <option
                  :disabled="
                    this.registeredPersons(this.kurs) + 6 >
                    this.filteredKurses.max_personen
                  "
                  value="6"
                >
                  6 PERSONEN
                </option>
                <option
                  :disabled="
                    this.registeredPersons(this.kurs) + 7 >
                    this.filteredKurses.max_personen
                  "
                  value="7"
                >
                  7 PERSONEN
                </option>
                <option
                  :disabled="
                    this.registeredPersons(this.kurs) + 8 >
                    this.filteredKurses.max_personen
                  "
                  value="8"
                >
                  8 PERSONEN
                </option>
                <option
                  :disabled="
                    this.registeredPersons(this.kurs) + 9 >
                    this.filteredKurses.max_personen
                  "
                  value="9"
                >
                  9 PERSONEN
                </option>
                <option
                  :disabled="
                    this.registeredPersons(this.kurs) + 10 >
                    this.filteredKurses.max_personen
                  "
                  value="10"
                >
                  10 PERSONEN
                </option>
                <option
                  :disabled="
                    this.registeredPersons(this.kurs) + 11 >
                    this.filteredKurses.max_personen
                  "
                  value="11"
                >
                  11 PERSONEN
                </option>
                <option
                  :disabled="
                    this.registeredPersons(this.kurs) + 12 >
                    this.filteredKurses.max_personen
                  "
                  value="12"
                >
                  12 PERSONEN
                </option>
                <option
                  :disabled="
                    this.registeredPersons(this.kurs) + 13 >
                    this.filteredKurses.max_personen
                  "
                  value="13"
                >
                  13 PERSONEN
                </option>
                <option
                  :disabled="
                    this.registeredPersons(this.kurs) + 14 >
                    this.filteredKurses.max_personen
                  "
                  value="14"
                >
                  14 PERSONEN
                </option>
                <option
                  :disabled="
                    this.registeredPersons(this.kurs) + 15 >
                    this.filteredKurses.max_personen
                  "
                  value="15"
                >
                  15 PERSONEN
                </option>
                <option
                  :disabled="
                    this.registeredPersons(this.kurs) + 16 >
                    this.filteredKurses.max_personen
                  "
                  value="16"
                >
                  16 PERSONEN
                </option>
              </select>
            </div>

            <!-- Looped Vorname/Nachname/Geburtsdarum/Ahv/CZV/Email fur learning field x(anzahl_personen) -->
            <div
              v-for="field in dynamicallyLoopedUserFields"
              :key="field.index"
            >
              <div v-html="field"></div>
            </div>

            <!-- Mutual fields -->

            <!-- Bemerkung -->
            <div class="form-group mb-4">
              <label for=""><b>Bemerkung</b></label>
              <input type="text" class="form-control py-5" name="bemerkung" />
            </div>

            <!-- AGB checkbox -->
            <div class="form-check mb-4">
              <h6 style="margin-left: -20px">
                <b>AGB <span class="text-danger">*</span></b>
              </h6>
              <label class="form-check-label">
                <input
                  v-model="agb"
                  type="checkbox"
                  class="form-check-input"
                  name="agb-checkbox"
                  value="agb"
                />
                Ich akzeptiere hiermit die
                <router-link to="/agb">
                  Allgemeinen Geschäftsbedingungen</router-link
                >
              </label>
            </div>

            <button
              @click="senden()"
              :disabled="!anzahl_personen || !agb"
              class="btn btn-success rounded-0 mb-5"
              type="submit"
            >
              Senden
            </button>
          </form>
        </div>
      </div>
    </b-container>

    <!-- bg green -->
    <b-container fluid>
      <b-row style="margin-bottom: 0px" class="bg-green">
        <b-container> </b-container>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss">
.staplerkurse {
  position: relative;
  .header-titel {
    position: absolute;
    left: 50%;
    top: 318px;
    color: white;
  }
  h4 {
    color: #333;
  }
  b {
    color: #777;
  }
  p {
    color: #777;
  }
}
.formular {
  margin-bottom: 100px;
  .form-control {
    background: #e9e9e9;
    border: 0px;
    border-radius: 0px;
  }
  p,
  label,
  h6 {
    color: #777;
  }
  a {
    color: #00b252;
  }
}
@media only screen and (max-width: 600px) {
  .staplerkurse {
    margin-top: 85px;
  }
}
</style>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "MotorsageWeiterfuhrungskurs5Tage",
  mounted() {
    // get countries list
    axios.get("https://restcountries.com/v3.1/all").then((response) => {
      this.countries = response.data;
    });
  },
  data() {
    return {
      name: "",
      adresse: "",
      telefon: "",
      email: "",
      geburtsdatum: "",
      ahv: "",
      kurs: "motorsage-weiterfuhrungskurs-5-tage",
      anmeldeinformation: "privatperson",
      countries: [],
      selected_country: "Switzerland",
      anzahl_personen: 1,
      agb: "",
      selected_date: moment().format("YYYY-MM-DD"),
    };
  },

  methods: {
    senden() {
      axios
        .post(`${process.env.VUE_APP_URL}/api/buchungens/`, {
          data: {
            name: this.name,
            adresse: this.adresse,
            telefon: this.telefon,
            email: this.email,
            geburtsdatum: this.geburtsdatum,
            ahv: this.ahv,
            anzahl_personen: this.anzahl_personen,
            kurs: "motorsage-weiterfuhrungskurs-5-tage",
            datum: this.selected_date,
          },
        })
        .then((response) => {
          if (response.status == "200") {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    refreshPage() {
      window.location.reload();
    },
    isCourseAvailable(ymd, kurs_name) {
      let filtered;
      filtered = this.kurses.filter(
        (v) =>
          ymd == v.datum &&
          this.kurs == v.name &&
          this.registeredPersons(kurs_name) < v.max_personen
      );
      return filtered[0];
    },
    registeredPersons(kurs_name) {
      let filtered;
      let sum = 0;
      filtered = this.buchungens.filter(
        (v) => kurs_name == v.kurs && this.selected_date == v.datum
      );
      for (let i = 0; i < filtered.length; i++) {
        sum = sum + filtered[i].anzahl_personen;
      }
      return sum;
    },
    dateDisabled(ymd) {
      return (
        ymd < moment(new Date()).format("YYYY-MM-DD") ||
        !this.isCourseAvailable(ymd, this.kurs)
      );
    },
  },

  computed: {
    buchungens() {
      return this.$store.state.buchungens;
    },
    kursseiten() {
      return this.$store.state.kursseiten;
    },
    node_env() {
      return this.$store.state.node_env;
    },
    api_url() {
      return this.$store.state.api_url;
    },
    kurses() {
      return this.$store.state.kurses;
    },
    individuelle() {
      return this.$store.state.individuelle;
    },
    filteredKurses() {
      let filtered;
      filtered = this.kurses.filter(
        (v) => this.kurs == v.name && this.selected_date == v.datum
      );
      return filtered[0];
    },
    dynamicallyLoopedUserFields() {
      let dynamicallyLoopedUserFields = [];

      for (let i = 0; i < this.anzahl_personen; i++) {
        dynamicallyLoopedUserFields.splice(
          i,
          0,
          `
          <!-- Vorname Nachname -->
          <ul class="list-inline mb-1">
              <li class="list-inline-item">
                <div class="form-group">
                  <label for=""
                    ><b>Name (P${
                      i + 1
                    })<span class="text-danger">*</span></b></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    name="vorname_p${i + 1}"
                  />
                  <small class="form-text text-muted">Vorname</small>
                </div>
              </li>
              <li class="list-inline-item">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="nachname_p${i + 1}"
                  />
                  <small class="form-text text-muted">Nachname</small>
                </div>
              </li>
            </ul>


            <!-- Geburtsdatum -->
               <div class="form-group mb-4">
                <label for=""
                  ><b>Gerbutrsdatum P(${i + 1}) </b
                  ><span class="text-danger">*</span></label
                >
                <input
                  placeholder="tt.mm.jjjj"
                  type="text"
                  class="form-control w-50"
                  name="geburtsdatum_p${i + 1}"
                />
              </div>

              <!-- E-learning email -->
              <div class="form-group mb-4">
                <label for=""><b>E-Mail für E-Learning P(${i + 1})</b></label>
                <input
                  type="email"
                  class="form-control w-50"
                  name="e-learning-email-p${i + 1}"
                />
              </div>
            `
        );
      }
      return dynamicallyLoopedUserFields;
    },
  },
};
</script>
